<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="road_warrant.account" class="form-group col-sm-12">
          <label for="account_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="road_warrant.account.account_name">
      </div>
      <div class="form-group col-sm-12">
          <label for="road_warrant_code">Road Warrant Code:</label>
          <input class="form-control" disabled name="road_warrant_code" type="text" v-model="road_warrant.road_warrant_code">
      </div>
      <div v-if="road_warrant.vehicle" class="form-group col-sm-12">
          <label for="account_id">Vehicle:</label>
          <input class="form-control" disabled name="vehicle_id" type="text" :value="road_warrant.vehicle.vehicle_no + ' - ' + road_warrant.vehicle.vehicle_code">
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="road_warrant.driver_id" :options="driverOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="road_warrant_date">Road Warrant Date:</label>
        <input class="form-control" name="road_warrant_date" type="date" disabled v-model="road_warrant_date">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/road_warrants">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    components:{
      Select2,
    },
    computed: {
        ...mapState('road_warrants', ['road_warrant', 'onRequest']),
        ...mapState('vehicles', ['vehicles']),
        ...mapState('drivers', ['drivers']),
        road_warrant_date:{
          get(){
            if(this.road_warrant && this.road_warrant.road_warrant_date){
              return moment(this.road_warrant.road_warrant_date).format("YYYY-MM-DD")
            }
            return null
          },
          set(val){
            this.road_warrant.road_warrant_date = val
          }
        },
    },
    data(){
      return {
        driver_id: null,
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.road_warrant.road_warrant_date = this.road_warrant_date
        this.edit(this.road_warrant)
      },
      ...mapActions('road_warrants', ['edit', 'get']),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Road Warrant')
      this.get(this.$route.params.id)
    },
    watch:{
      road_warrant(nextState, prevState){
        if(nextState !== prevState){
          if(nextState.account_id){
            this.getDrivers({account_id : nextState.account_id})
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>
