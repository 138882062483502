<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="account_id">Account:</label>
        <Select2 name="account_id" v-model.number="road_warrant.account_id" :options="accountOptions"  @change="onChangeAccount($event)" @select="onChangeAccount($event)"/>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="vehicle_id">Vehicle:</label>
        <Select2 name="vehicle_id" required v-model.number="road_warrant.vehicle_id" :options="vehicleOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12">
        <label for="driver_id">Driver:</label>
        <Select2 name="driver_id" v-model.number="road_warrant.driver_id" :options="driverOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="road_warrant_date">Road Warrant Date:</label>
        <input class="form-control" name="date" type="date" required v-model="road_warrant.road_warrant_date">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/road_warrants">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import moment from 'moment'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('vehicles', ['vehicles']),
      ...mapState('drivers', ['drivers']),
      ...mapState('road_warrants', ['onRequest']),
    },
    components:{
      Select2
    },
    data(){
      return {
        road_warrant: {
          account_id: '',
          vehicle_id: '',
          driver_id: '',
          road_warrant_date: moment().format("YYYY-MM-DD"),
        },
        accountOptions: [],
        vehicleOptions: [],
        driverOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.add(this.road_warrant)
      },
      ...mapActions('road_warrants', ['add']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('vehicles', {getVehicles: 'get_vehicle_by_account'}),
      ...mapActions('drivers', {getDrivers: 'get_driver_by_account'}),
      onChangeAccount(event){
        this.getVehicles({account_id: parseInt(event.id)})
        this.getDrivers({account_id: parseInt(event.id)})
        this.road_warrant.vehicle_id = ''
        this.road_warrant.driver_id = ''
      },
    },
    created() {
      this.$emit('onChildInit', 'Add New Road Warrant')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
      vehicles(nextState, prevState){
        if(nextState !== prevState){
          this.vehicleOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.vehicleOptions.push({id:item.id, text:item.vehicle_no +' - '+item.vehicle_code})
            })
          }
          return
        }
      },
      drivers(nextState, prevState){
        if(nextState !== prevState){
          this.driverOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.driverOptions.push({id:item.id, text:item.name})
            })
          }
          return
        }
      },
    }
  }
</script>
